const palette = {
  colorTone1: "#d7dadc",
  colorTone2: "#818384",
  colorTone3: "#565758",
  colorTone4: "#3a3a3c",
  colorTone5: "#272729",
  colorTone6: "#1a1a1b",
  colorTone7: "#121213",
  opacity50: "rgba(0, 0, 0, 0.5)",
  green: "#6aaa64",
  darkendGreen: "#538d4e",
  yellow: "#c9b458",
  darkendYellow: "#b59f3b",
  lightGray: "#d8d8d8",
  gray: "#86888a",
  darkGray: "#939598",
  white: "#fff",
  black: "#212121",
  orange: "#f5793a",
  blue: "#85c0f9",
};

export default {
  colorBackground: palette.colorTone7,
  colorPresent: palette.darkendYellow,
  colorCorrect: palette.darkendGreen,
  colorAbsent: palette.colorTone4,
  tileTextColor: palette.colorTone1,
  tileTextColorInput: palette.colorTone1,
  tileBorderColorInput: palette.colorTone3,
  tileBgColorInput: palette.colorTone7,
  keyTextColor: palette.colorTone1,
  keyBg: palette.colorTone2,
  modalContentBg: palette.colorTone7,
  ...palette,
};
