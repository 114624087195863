const palette = {
  colorTone1: "#1a1a1b",
  colorTone2: "#787c7e",
  colorTone3: "#878a8c",
  colorTone4: "#d3d6da",
  colorTone5: "#edeff1",
  colorTone6: "#f6f7f8",
  colorTone7: "#ffffff",
  opacity50: "rgba(0, 0, 0, 0.5)",
  green: "#6aaa64",
  darkendGreen: "#538d4e",
  yellow: "#c9b458",
  darkendYellow: "#b59f3b",
  lightGray: "#d8d8d8",
  gray: "#86888a",
  darkGray: "#939598",
  white: "#fff",
  black: "#212121",
  orange: "#f5793a",
  blue: "#85c0f9",
};

export default {
  colorBackground: palette.colorTone7,
  colorPresent: palette.yellow,
  colorCorrect: palette.green,
  colorAbsent: palette.colorTone2,
  tileTextColor: palette.colorTone7,
  tileTextColorInput: palette.colorTone1,
  tileBorderColorInput: palette.colorTone3,
  tileBgColorInput: palette.colorTone7,
  keyTextColor: palette.colorTone1,
  keyBg: palette.colorTone4,
  modalContentBg: palette.colorTone7,
  ...palette,
};
